import { useEffect, useRef } from 'react';
import './index.css';
import {
    widget,
    ChartingLibraryWidgetOptions,
    LanguageCode,
    ResolutionString,
    ChartingLibraryFeatureset,
} from '../../charting_library/';
import * as React from 'react';
import { TONDataFeedProvider, TONDataFeedProviderConfiguration } from '../../tondatafeed';

export interface ChartContainerProps {
    symbol: ChartingLibraryWidgetOptions['symbol'];
    interval: ChartingLibraryWidgetOptions['interval'];

    // BEWARE: no trailing slash is expected in feed URL
    datafeedUrl: string;
    libraryPath: ChartingLibraryWidgetOptions['library_path'];
    chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
    chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
    clientId: ChartingLibraryWidgetOptions['client_id'];
    userId: ChartingLibraryWidgetOptions['user_id'];
    fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
    autosize: ChartingLibraryWidgetOptions['autosize'];
    studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
    container: ChartingLibraryWidgetOptions['container'];
}

const getLanguageFromURL = (): LanguageCode | null => {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
};


const urlParams = new URLSearchParams(window.location.search);




export const TVChartContainer = () => {
    const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

    const defaultProps: Omit<ChartContainerProps, 'container'> = {
        symbol: urlParams.get('symbol') ??
            window?.frameElement?.getAttribute("data-symbol") ??
            'dedust:EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c/EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA',
        interval: (urlParams.get('interval') ?? window?.frameElement?.getAttribute("data-interval") ?? '60') as ResolutionString,
        datafeedUrl: 'https://api.redoubt.online',
        libraryPath: 'charting_library/',
        chartsStorageUrl: 'https://saveload.tradingview.com',
        chartsStorageApiVersion: '1.1',
        clientId: 'tradingview.com',
        userId: 'public_user_id',
        fullscreen: false,
        autosize: true,
        studiesOverrides: {

            // "volume.volume.color.0": "rgba(0, 255, 255, 0.8)",
            // "volume.volume.color.1": "#0000FF",
        },
    };

    const datafeedConfig: TONDataFeedProviderConfiguration = {
        graphQLWsUrl: process.env.REACT_APP_GRAPHQL_API_WS ?? 'wss://graphql.redoubt.online/datafeed',
        graphQLHttpUrl: process.env.REACT_APP_GRAPHQL_API_HTTP ?? 'https://graphql.redoubt.online/datafeed'
        // graphQLWsUrl: 'ws:///127.0.0.1:8088/graphql ',
        // graphQLHttpUrl: 'http://127.0.0.1:8088/graphql'
    }

    const datafeed = new TONDataFeedProvider(datafeedConfig)

    const humanFormat = (value: number) => {
        return Intl.NumberFormat('en-US', {
            notation: "compact",
            maximumFractionDigits: 1
        }).format(value);
    }

    let disabledFeatures: ChartingLibraryFeatureset[] = ['uppercase_instrument_names']
    if (urlParams.get('chart_only') === 'true') {
        const mobileFeatures: ChartingLibraryFeatureset[] = ['left_toolbar', 'header_widget',
            'timeframes_toolbar', 'edit_buttons_in_legend', 'context_menus',
            'control_bar', 'border_around_the_chart'];
        disabledFeatures = [...disabledFeatures, ...mobileFeatures];
    }


    useEffect(() => {
        const widgetOptions: ChartingLibraryWidgetOptions = {
            symbol: defaultProps.symbol as string,
            // BEWARE: no trailing slash is expected in feed URL
            // tslint:disable-next-line:no-any
            datafeed: datafeed,
            interval: defaultProps.interval as ChartingLibraryWidgetOptions['interval'],
            container: chartContainerRef.current,
            library_path: defaultProps.libraryPath as string,

            locale: getLanguageFromURL() || 'en',
            disabled_features: disabledFeatures,
            enabled_features: ['show_symbol_logos', 'show_exchange_logos', 'pricescale_currency', 'use_localstorage_for_settings'],
            charts_storage_url: defaultProps.chartsStorageUrl,
            charts_storage_api_version: defaultProps.chartsStorageApiVersion,
            client_id: defaultProps.clientId,
            user_id: defaultProps.userId,
            fullscreen: defaultProps.fullscreen,
            autosize: defaultProps.autosize,
            studies_overrides: defaultProps.studiesOverrides,
            theme: (urlParams.get('theme') ?? 'dark') === 'dark' ? 'dark' : 'light',
            overrides: {
                'mainSeriesProperties.statusViewStyle.showExchange': false,
                "paneProperties.legendProperties.showVolume": false,
                "mainSeriesProperties.statusViewStyle.showInterval": false,
                "mainSeriesProperties.visible": true

            },
            custom_formatters: {
                timeFormatter: {
                    format: (date) => {
                        return date.getUTCHours().toString().padStart(2, '0') + ':' + date.getUTCMinutes().toString().padStart(2, '0')
                    },
                    formatLocal: (date) => {
                        return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
                    }
                },
                dateFormatter: {
                    format: (date) => {
                        return date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                    },
                    formatLocal: (date) => {
                        return date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                    }
                },

                studyFormatterFactory: (format, symbolInfo) => {

                    if (format.type === 'volume') {
                        return {
                            format: (value: number) => humanFormat(value) + ' $'
                        };
                    }
                    return null; // this is to use default formatter;
                },
            }


        };
        // console.log(widgetOptions.customFormatters?.dateFormatter)
        const tvWidget = new widget(widgetOptions);

        tvWidget.onChartReady(() => {
            tvWidget.watermark().visibility().setValue(true)
            // tvWidget.watermark().color().setValue("#87CEEB")
            tvWidget.watermark().setContentProvider((x) => {
                return [{ text: "Powered by re:doubt", fontSize: 96, lineHeight: 117, vertOffset: 0 }]
            })


            // tvWidget.headerReady().then(() => {
            //     const button = tvWidget.createButton();
            //     button.setAttribute('title', 'Click to show a notification popup');
            //     button.classList.add('apply-common-tooltip');
            //     button.addEventListener('click', () => tvWidget.showNoticeDialog({
            //         title: 'Notification',
            //         body: 'TradingView Charting Library API works correctly',
            //         callback: () => {
            //             console.log('Noticed!');
            //         },
            //     }));
            //     button.innerHTML = 'Check API';
            // });
        });

        return () => {
            tvWidget.remove();
        };
    });

    return (
        <div
            ref={chartContainerRef}
            className={'TVChartContainer'}
        />
    );
};

export { };
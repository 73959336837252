import React from 'react';
import './App.css';
import { TVChartContainer } from './components/TVChartContainer/index';

import { version } from './charting_library';


function App() {
  return (
    <div className="App">
      <TVChartContainer />
    </div>
  );
}

export default App;

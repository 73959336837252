import { Bar, DOMCallback, ErrorCallback, GetMarksCallback, HistoryCallback, IDatafeedChartApi, IExternalDatafeed, LibrarySymbolInfo, Mark, OnReadyCallback, PeriodParams, ResolutionString, ResolveCallback, SearchSymbolsCallback, ServerTimeCallback, SubscribeBarsCallback, SymbolResolveExtension, TimescaleMark } from './charting_library'
import { DatafeedConfiguration } from './charting_library/datafeed-api';
import { Client, ExecutionResult, createClient } from 'graphql-ws';
import { request, gql, GraphQLClient } from 'graphql-request';


export interface TONDataFeedProviderConfiguration {
    graphQLHttpUrl: string;
    graphQLWsUrl: string;
}

const ALL_SUPPORTED_RESULUTIONS = [
    "1" as ResolutionString, // 1 min
    "5" as ResolutionString, // 5 mins
    "15" as ResolutionString, // 15 mins
    "60" as ResolutionString, // 1 hour
    // "240" as ResolutionString, // 4 hours
    // "720" as ResolutionString, // 12 hours
    // "1D" as ResolutionString
]

const RESOLUTION_MAP: { [key: string]: string } = {
    "1": "1m",
    "5": "5m",
    "15": "15m",
    "60": "1H",
    "240": "4H",
    "720": "12H",
    "1D": "1D"
}

interface IDataFeedVolume {
    ton: number;
    usd: number;
}

interface IDataFeedBar {
    time: number;
    open: number;
    high: number;
    low: number;
    close: number;
    volume: IDataFeedVolume;
}

export class TONDataFeedProvider implements IDatafeedChartApi, IExternalDatafeed {
    private readonly _wsClient: Client;
    private readonly _requestClient: GraphQLClient;
    private _subscriptions = new Map<string, () => void>();

    public constructor(configuration: TONDataFeedProviderConfiguration) {
        const token = 'fcaa57b96f9eeade3ebf21894d324b3afc90df753553be9c222897dadd559e19';
        this._wsClient = createClient({
            url: configuration.graphQLWsUrl,
            connectionParams: async () => {
                return {
                    headers: {
                        "X-API-Key": token,
                    }
                }
            }
        });
        this._requestClient = new GraphQLClient(configuration.graphQLHttpUrl);
        this._requestClient.setHeader('X-API-Key', token)
    }

    private toBar(bar: IDataFeedBar): Bar {
        return {
            time: bar.time * 1000,
            open: bar.open,
            high: bar.high,
            low: bar.low,
            close: bar.close,
            volume: bar.volume.usd
        }
    };

    onReady(callback: OnReadyCallback): void {
        console.log('[onReady]: Method call');
        const configurationData: DatafeedConfiguration = {
            // supports_search: true,
            // supports_group_request: false,
            supports_marks: true,
            supports_timescale_marks: true,
            supports_time: true,
            exchanges: [
                { value: "", name: "All Exchanges", desc: "" },
                { value: "dedust", name: "DeDust.io", desc: "DeDust.io DEX" },
                { value: "megaton", name: "Megaton.fi", desc: "Megaton.fi DEX" },
                { value: "ston.fi", name: "Ston.fi", desc: "Ston.fi DEX" },
            ],
            // symbols_types: [
            //     { name: "All types", value: "" },
            //     { name: "Stock", value: "stock" },
            //     { name: "Index", value: "index" }
            // ],
            supported_resolutions: ALL_SUPPORTED_RESULUTIONS
        }

        setTimeout(() => callback(configurationData));
    }
    getMarks?(symbolInfo: LibrarySymbolInfo, from: number, to: number, onDataCallback: GetMarksCallback<Mark>, resolution: ResolutionString): void {
        console.log('[getMarks]: Method call', symbolInfo);
        // throw new Error('Method not implemented.');
    }
    getTimescaleMarks?(symbolInfo: LibrarySymbolInfo, from: number, to: number, onDataCallback: GetMarksCallback<TimescaleMark>, resolution: ResolutionString): void {
        console.log('[getTimescaleMarks]: Method call', symbolInfo);
        // throw new Error('Method not implemented.');
    }
    getServerTime?(callback: ServerTimeCallback): void {
        console.log('[getServerTime]: Method call');
        this._requestClient.request<{ getServerTime: number }>(gql`query serverTime {
            getServerTime
          }`).then((res) => callback(res.getServerTime)).catch((err) => {
            console.error(err);
        });
    }
    searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): void {
        console.log('[searchSymbols]: Method call');
        this._requestClient.request<{
            getSymbols: [{
                ticker: string, short: string, full: string, exchange: string, exchange_logo: string,
                base_logo: string, quote_logo: string,
                description: string
            }]
        }>(
            gql`query symbols($query: String!, $exchange: String, $symbolType: String) {
                getSymbols(query: $query, exchange: $exchange, symbolType: $symbolType) {
                    ticker
                    short
                    full
                    exchange
                    description
                    ticker
                    exchange_logo
                    base_logo
                    quote_logo
                }
            }`, {
            query: userInput,
            exchange: exchange,
            symbolType: symbolType
        }).then((res) => {
            console.log(res)
            onResult(res.getSymbols.map(r => ({
                ticker: r.ticker,
                symbol: r.short,
                full_name: r.full,
                description: r.description,
                exchange: r.exchange,
                exchange_logo: r.exchange_logo,
                type: "crypto dex",
                logo_urls: [r.base_logo, r.quote_logo]
            })))
        }).catch((err) => {
            console.error(err);
        });
    }
    resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback, extension?: SymbolResolveExtension | undefined): void {
        console.log('[searchSymbols]: Method call');
        this._requestClient.request<{
            getSymbol: {
                ticker: string, short: string, full: string, exchange: string, exchange_logo: string,
                base_logo: string, quote_logo: string,
                description: string, quote: string, pricescale: number
            }
        }>(
            gql`query symbols($symbol: String!) {
                getSymbol(symbol: $symbol) {
                    ticker
                    short
                    full
                    exchange
                    description
                    ticker
                    quote
                    exchange_logo
                    base_logo
                    quote_logo
                    pricescale
                }
            }`, { symbol: symbolName }).then((res) => {
                console.log(res)
                const r = res.getSymbol;
                onResolve({
                    name: r.short,
                    ticker: r.ticker,
                    full_name: r.full,
                    session: '24x7',
                    timezone: 'Etc/UTC',
                    type: "crypto",
                    description: r.description,
                    exchange: r.exchange,
                    minmov: 1,
                    pricescale: r.pricescale,
                    has_intraday: true,
                    has_weekly_and_monthly: false,
                    supported_resolutions: ALL_SUPPORTED_RESULUTIONS,
                    volume_precision: 1,
                    listed_exchange: r.exchange,
                    format: 'price',
                    data_status: 'streaming',
                    currency_code: r.quote,
                    // exchange_logo: r.exchange_logo,
                    // type: "crypto dex",
                    logo_urls: [r.base_logo, r.quote_logo]
                });
            }).catch((err) => {
                onError("Unable to resolve symbol: " + symbolName)
                console.error(err);
            });
    }
    getBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParams, onResult: HistoryCallback, onError: ErrorCallback): void {
        const { from, to, countBack, firstDataRequest } = periodParams;
        console.log('[getBars]: Method call', symbolInfo, resolution, countBack, new Date(from * 1000).toDateString(), new Date(to * 1000).toDateString());

        this._requestClient.request<{
            getBars: {
                bars: [IDataFeedBar],
                noData: boolean
            }
        }>(
            gql`query getBars($symbol: String!, $frame: String!, $from: Int!, $to: Int!, $countBack: Int!) {
                    getBars(
                        symbol: $symbol,
                        timeframe: $frame,
                        period: {from: $from, to: $to, countBack: $countBack}
                        ) {
                            noData
                            bars{
                                time
                                open
                                high
                                low
                                close
                                volume {
                                    ton
                                    usd
                                }
                            }
                        }
                }`, {
            symbol: symbolInfo.ticker,
            frame: RESOLUTION_MAP[resolution],
            from: from,
            to: to,
            countBack: countBack
        }).then((res) => {
            console.log("Got bars:", res.getBars.bars.length,
                new Date(res.getBars.bars[0]?.time * 1000).toDateString(),
                new Date(res.getBars.bars[res.getBars.bars.length - 1]?.time * 1000).toDateString(),
                res)
            onResult(res.getBars.bars.map(this.toBar), { noData: res.getBars.noData })
        }).catch((err) => {
            onError("Unable to fetch bars: " + err)
            console.error(err);
        });
    }
    subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void): void {
        const onNext = (value: ExecutionResult) => {
            if (value.data) {
                console.log("value.data", value.data)
                let update = value.data as { updates: IDataFeedBar }
                // console.log("update", update)
                console.log("Update bar", update, this.toBar(update.updates))
                onTick(this.toBar(update.updates))
            }
            // console.log(data, value.data.updates, data.concat(data, value.data.updates))
            console.log("Update received", value)
        }
        let ws = new Promise((resolve, reject) => {
            let unsubscribe = this._wsClient.subscribe({
                query: `subscription updates($symbol: String!, $frame: String!, $listenerId: String!)
            {
                updates(
                    symbol: $symbol,
                    timeframe: $frame,
                    listenerId: $listenerId
                ) {
                    time
                    open
                    high
                    low
                    close
                    volume {
                        ton
                        usd
                    }
                }
            }`,
                variables: {
                    symbol: symbolInfo.ticker,
                    frame: RESOLUTION_MAP[resolution],
                    listenerId: listenerGuid
                }
            }, {
                next: onNext,
                error: reject,
                complete: () => resolve(null),
            })
            this._subscriptions.set(listenerGuid, unsubscribe)
        })
        console.log('[subscribeBars]: Method call', symbolInfo, resolution, listenerGuid);
    }
    unsubscribeBars(listenerGuid: string): void {
        console.log('[unsubscribeBars]: Method call', listenerGuid);
        let callback = this._subscriptions.get(listenerGuid)
        if (callback === undefined) {
            console.warn("No subscription callbaback for " + listenerGuid)
        } else {
            callback();
        }
    }
    subscribeDepth?(symbol: string, callback: DOMCallback): string {
        throw new Error('Method not implemented.');
    }
    unsubscribeDepth?(subscriberUID: string): void {
        throw new Error('Method not implemented.');
    }
    getVolumeProfileResolutionForPeriod?(currentResolution: ResolutionString, from: number, to: number, symbolInfo: LibrarySymbolInfo): ResolutionString {
        throw new Error('Method not implemented.');
    }

}